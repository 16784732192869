<template>
    <PublicForm :busy="busy">
        <v-form v-model="form.valid">
            <v-row dense>
                <v-col class="py-8">
                    <BreezeLogo style="height: 50px" max-height="50" />
                </v-col>
            </v-row>
            <v-row v-if="!resetSuccess" dense>
                <v-col cols="12" class="px-6">
                    <LoginPageError :error="error" />
                    <p>
                        Please enter your username below and we will send
                        you a link to reset your password.
                    </p>
                    <p>
                        If you don't have access to your email anymore or you
                        have lost your 2FA device please
                        <a href="mailto:help@breezeconnect.com.au"
                            >contact Breeze Connect Support</a
                        >
                        for assistance.
                    </p>
                </v-col>
                <v-col cols="12" class="px-6">
                    <v-text-field
                        label="Username"
                        ref="username"
                        name="username"
                        v-model="form.username"
                        prepend-icon="mdi-account"
                        type="text"
                        :rules="[
                            (v) => !!v || 'Username is required',
                        ]"
                        :disabled="busy"
                        v-on:keyup.enter="requestPasswordReset"
                        required
                        autofocus
                    />
                </v-col>
                <v-fade-transition hide-on-leave>
                    <v-col
                        key="reCaptcha"
                        v-show="!recaptcha.response"
                        align="center"
                        style="min-height: 102px"
                        cols="12"
                    >
                        <vue-recaptcha
                            ref="captcha"
                            :sitekey="recaptcha.sitekey"
                            :loadRecaptchaScript="true"
                            :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                            @verify="recaptchaVerified"
                            @expired="recaptchaExpired"
                        />
                    </v-col>
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                    <v-col
                        key="loginButton"
                        v-show="recaptcha.response"
                        style="min-height: 102px"
                        class="pt-8 px-6"
                        cols="12"
                    >
                        <v-btn
                            v-on:click="requestPasswordReset"
                            color="primary"
                            :disabled="
                                busy || !form.valid || !recaptcha.response
                            "
                            block
                            large
                            >Request Password Reset</v-btn
                        >
                    </v-col>
                </v-fade-transition>
            </v-row>
            <v-row v-if="resetSuccess" dense>
                <v-col cols="12" class="px-6 pb-6 text-center">
                    <v-icon size="120" color="success"
                        >mdi-check-circle-outline</v-icon
                    >
                </v-col>
                <v-col cols="12" class="px-6">
                    <p>
                        An email has been sent to you with instructions to reset
                        your password.
                    </p>
                    <p>
                        If you do not receive this email please
                        <a href="mailto:help@breezeconnect.com.au"
                            >contact Breeze Connect Support</a
                        >
                        for assistance.
                    </p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="text-center my-3" :disabled="busy">
                    <router-link
                        :to="{ name: 'Login' }"
                        :disabled="!busy"
                        :event="!busy ? 'click' : ''"
                        v-bind:class="{ 'text--disabled': busy }"
                        >Return to login page</router-link
                    >
                </v-col>
            </v-row>
        </v-form>
    </PublicForm>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import PublicForm from '../../components/pieces/Public/PublicForm';
import BreezeLogo from '../../components/pieces/Public/BreezeLogo';
import LoginPageError from '../../components/pieces/Public/LoginPageError';
import apiMixin from '../../mixins/apiMixin';
export default {
    name: 'ForgotPassword',
    mixins: [apiMixin],
    data() {
        return {
            form: {
                username: '',
            },
            busy: false,
            error: '',
            resetSuccess: false,
            recaptcha: {
                sitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
                response: '',
            },
        };
    },
    methods: {
        async requestPasswordReset(event) {
            event.preventDefault();
            if (!this.recaptcha.response) return;
            this.busy = true;
            const response = await this.Api.send('post','auth/reset/new', {
                login: this.form.username,
                recaptcha_response: this.recaptcha.response,
            });
            if (response.success) {
                this.resetSuccess = true;
                this.busy = false;
            } else {
                this.recaptcha.response = '';
                this.$refs['captcha'].reset();
                this.error =
                    response.errorDetail || 'An unknown error occurred';
                this.form.username = '';
                this.busy = false;
            }
        },
        recaptchaVerified: function (response) {
            this.recaptcha.response = response;
        },
        recaptchaExpired: function () {
            this.recaptcha.response = '';
        },
    },
    computed: {
        theme: function () {
            if (this.$vuetify.theme.dark) {
                return 'dark';
            } else {
                return 'light';
            }
        },
    },
    components: {
        LoginPageError,
        BreezeLogo,
        PublicForm,
        VueRecaptcha,
    },
};
</script>
